import React, { useState } from "react"
import styles from "./index.module.css";
import { Button, Grid, Typography, InputBase } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ProfileScreen from '../../../Profile';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import HttpHeader from '../../../../header';
import { Field, FieldProps, Form, Formik } from 'formik';
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import AddressPicker from '../../../AddressPicker/AddressPicker';
import { ApolloError, useMutation } from '@apollo/client';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { formatGraphQLErrorMessage } from '../../../utils';
import { UPDATE_BUSINESS_CUSTOMER } from '../../../../graphql/user/updateUser';
import * as Yup from "yup";
import { BusinessCustomerStatus, UserRole } from '../../../../reducers/auth/types';

const httpHeader = HttpHeader.getInstance();

const StyledInput = withStyles(() =>
  createStyles({
    root: {
      border: "1px solid #c0c0c0",
      borderRadius: 6,
      padding: "8px 10px",
      marginTop: 5,
      '&.Mui-focused': {
        borderColor: "var(--primary-color)"
      },
      '&.Mui-error': {
        borderColor: "#D13135",
        color: "#D13135"
      }
    },
  }),
)(InputBase);

const Account = () => {
  const snackbar = useSnackBar();
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const [editing, setEditing] = useState(false);

  const [updateBusinessCustomer] = useMutation(UPDATE_BUSINESS_CUSTOMER, {
    onCompleted: (data) => {
      setEditing(!editing);
      window.location.reload();
      snackbar({
        message: "Customer Information Saved",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const getCountryFromLocaleString = (localeString: string) => {
    // var alpha2 = localeString.match("(^|[a-z])_[A-Z]{2}");
    const alpha2 = localeString.slice(-2);
    if (alpha2) {
      return alpha2;
    }
    return "GB"
  }

  const customerInfoSchema = Yup.object().shape({
    billing: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid Billing email.")
        .required("Billing email is required."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-is-b-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" });
            }
            return true;
          })
          .required("Phone number is required.")
      })
    }),
    businessName: Yup.string().required("Business name is required."),
    registeredAddress: Yup.object().shape({
      fullAddress: Yup.string().required("Registered Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required")
    }).required("Registered Address is required"),
    tradingAddress: Yup.object().shape({
      fullAddress: Yup.string().required("Trading Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required")
    }).required("Trading Address is required"),
  });
  const convertNotesToHTML = (note: string | undefined) => {
    if (typeof note !== 'string') {
      return note;
    }
    if (!note.includes('\n')) {
      return note;
    }
    const newNote = note.split('\n').reduce<JSX.Element[]>(
      (acc, line, index) => {
        acc.push(<React.Fragment key={index}>{line}</React.Fragment>);
        acc.push(<br key={`br-${index}`} />);
        return acc;
      }, []
    );
    return newNote
  };
  const HTMLdeclinedNote = convertNotesToHTML(user?.businesscustomers?.declinedReason);
  return (

    <div className={styles.column}>
      {user && user.customer && <ProfileScreen customer={user.customer} onAccount />}
      {user && user.businesscustomers && user.role !== UserRole.DRIVER &&
        <Formik
          initialValues={user.businesscustomers}
          validationSchema={customerInfoSchema}
          onSubmit={(values) => {
            const { approvedDrivers, status, declinedReason, ...rest } = values;
            updateBusinessCustomer({
              variables: {
                businessCustomer: rest,
                businessCustomerId: values.id
              }
            });
          }}
        >
          {(formikProps) => (
            <Form>
              <div className="flex cross-center margin-bottom padding-bottom">
                <div className={styles.avatar}>
                  <span className="font-large bold">{user.firstName.slice(0, 1).toUpperCase()}{user.lastName.slice(0, 1).toUpperCase()}</span>
                  {/* <img src="https://picsum.photos/200/200/?blur=10" /> */}
                </div>
                <div className="flex fill col-flex margin-left margin-right">
                  <Typography variant="h3" className="semi-bold" style={{ marginLeft: 1, marginBottom: 4 }}>{user.firstName} {user.lastName}</Typography>
                  <span className="flex cross-center font-medium opacity50">
                    <EmailIcon style={{ fontSize: 17, marginRight: 3 }} />
                    <span>{user.email}</span>
                    <PhoneIcon style={{ fontSize: 17, marginRight: 3, marginLeft: 10 }} />
                    <span className="">{user.phoneNumber.phone}</span>
                  </span>
                </div>
                <div className="flex cross-center">
                  {!editing && <Button
                    onClick={() => {
                      setEditing(true)
                    }}
                    variant="outlined"
                    color="default" size="large"
                    disableRipple
                    style={{ textTransform: "initial", fontSize: 14, fontWeight: 500, padding: "6px 14px" }}
                  >
                    Edit
                  </Button>}
                  <span className='margin-left'></span>
                  <Button
                    onClick={() => {
                      httpHeader.resetHeaders();
                      localStorage.clear();
                      window.location.replace("/");
                    }}
                    variant="outlined"
                    color="default" size="large"
                    disableRipple
                    style={{ textTransform: "initial", fontSize: 14, fontWeight: 500, padding: "6px 14px", whiteSpace: "nowrap"}}
                  >
                    Log out
                  </Button>
                </div>
              </div>
              <div className="flex cross-center margin-top">
                <Typography className="semi-bold">Business Account</Typography>
                <span className="flex fill margin-left" style={{ borderBottom: "1px solid #e9e9e9", marginTop: 4 }}></span>
              </div>
              <div className={styles.box}>
                {website.enableBusinessCustomerSignup && (
                  <>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1">
                      Verification Status :
                      <span style={{
                        fontWeight: "bold", color: user?.businesscustomers?.status === BusinessCustomerStatus.PENDING ||
                        user?.businesscustomers?.status === BusinessCustomerStatus.DECLINED ? "red" : "green"
                      }}> {user?.businesscustomers?.status} </span>
                    </Typography>
                  </Grid>
                  {user?.businesscustomers?.declinedReason && user.businesscustomers?.status === BusinessCustomerStatus.DECLINED && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="body1">
                        Note:
                        <span style={{ fontWeight: "bold" }}> {HTMLdeclinedNote} </span>
                      </Typography>
                    </Grid>
                  )}
                </>
                )}
                <Typography className="semi-bold padding-bottom--l">Company Information</Typography>
                <Grid container spacing={2}>
                  <Grid xs={6} md={3} item>
                    <span className="font-medium opacity50">Business Name*</span>
                    {!editing ? <Typography >{formikProps.values.businessName}</Typography> :
                      <Field name="businessName">
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <>
                            <StyledInput
                              {...field}
                              placeholder="Enter Business Name"
                              fullWidth
                              error={touched && Boolean(error)}
                            />
                            <Typography variant="body2" color="error" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                          </>
                        )}
                      </Field>}
                  </Grid>
                  <Grid xs={6} md={3} item>
                    <span className="font-medium opacity50">Company Reg. No.</span>
                    {!editing ? <Typography >{formikProps.values.registrationNumber}</Typography> :
                      <Field name="registrationNumber">
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <>
                            <StyledInput
                              {...field}
                              placeholder="Enter Registration Number"
                              fullWidth
                              error={touched && Boolean(error)}
                            />
                          </>
                        )}
                      </Field>}
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <span className="font-medium opacity50">Tax/VAT Registration No.</span>
                    {!editing ? <Typography >{formikProps.values.VAT}</Typography> :
                      <Field name="VAT">
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <>
                            <StyledInput
                              {...field}
                              placeholder="Enter Tax/VAT Registration Number"
                              fullWidth
                              error={touched && Boolean(error)}
                            />
                          </>
                        )}
                      </Field>}
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <span className="font-medium opacity50">Registered Address*</span>
                    {!editing ? <Typography >{formikProps.values.registeredAddress.fullAddress}</Typography> :
                      <AddressPicker
                        fieldName="registeredAddress"
                        required
                        onChange={(address) => {
                          if (address) {
                            formikProps.setFieldValue("registeredAddress", address)
                          }
                        }}
                      />}
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <span className="font-medium opacity50">
                      <span >Trading Address*</span>
                      {editing && <span onClick={() => {
                        formikProps.setFieldValue("tradingAddress", formikProps.values.registeredAddress)
                        formikProps.setFieldValue("tradingAddressSame", true)
                        }} 
                        className='margin-left' style={{ color: "var(--theme-primary)", textDecoration: "underline", cursor: "pointer" }}>Same as Registered Address</span>}
                    </span>
                    {!editing ? <Typography>{formikProps.values.tradingAddress.fullAddress}</Typography> :
                      <AddressPicker
                        fieldName="tradingAddress"
                        required
                        onChange={(address) => {
                          if (address) {
                            formikProps.setFieldValue("tradingAddress", address)
                            formikProps.setFieldValue("tradingAddressSame", false)
                          }
                        }}
                      />}
                  </Grid>
                </Grid>
              </div>
              <div className={styles.box}>
                <Typography className="semi-bold padding-bottom--l">Billing Information</Typography>
                <Grid container spacing={2}>
                  <Grid xs={6} md={3} item>
                    <span className="font-medium opacity50">Billing Phone No.*</span>
                    {!editing ? <Typography >{formikProps.values.billing.phoneNumber.phone}</Typography> :
                      <Field
                        component={StyledInput}
                        name={"billing.phoneNumber.phone"}
                        required
                      >
                        {({ field, meta: { touched, error } }: FieldProps) => {
                          return (
                            <div className={`phone-input-container`} style={{ marginTop: 5 }}>
                              <div style={{ border: `1px solid ${(touched && Boolean(error)) ? '#D13135' : '#c0c0c0'}`, borderRadius: 6 }}>
                                <PhoneInput
                                  {...field}
                                  international={false}
                                  defaultCountry={getCountryFromLocaleString(website.organisation.locale)}   //
                                  placeholder="Enter phone number *"
                                  className={(touched && Boolean(error)) ? "PhoneInputInputError" : ""}
                                  name={"billing.phoneNumber.phone"}
                                  onChange={(val) => {
                                    formikProps.setFieldValue('billing.phoneNumber.phone', val)
                                  }}
                                  onCountryChange={(val) => {
                                    formikProps.setFieldValue('billing.phoneNumber.country', val)
                                  }}
                                />
                              </div>
                              <Typography variant="body2" color="error" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                            </div>
                          );
                        }}
                      </Field>}
                  </Grid>
                  <Grid xs={6} md={3} item>
                    <span className="font-medium opacity50">Billing Email*</span>
                    {!editing ? <Typography >{formikProps.values.billing.email}</Typography> :
                      <Field name="billing.email" >
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <>
                            <StyledInput
                              {...field}
                              placeholder="Enter Billing Email"
                              fullWidth
                              error={touched && Boolean(error)}
                            />
                            <Typography variant="body2" color="error" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                          </>
                        )}
                      </Field>}
                  </Grid>
                </Grid>
              </div>
              {editing && <div style={{ marginTop: 30 }}>
                <Button
                  onClick={() => {
                    setEditing(false);
                    formikProps.resetForm();
                  }}
                  variant="outlined"
                  color="default" size="large"
                  disableRipple
                  style={{ textTransform: "initial", fontSize: 14, fontWeight: 500, padding: "6px 14px" }}
                >
                  Cancel
                  </Button>
                <span className="margin-left"></span>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "var(--primary-color)",
                    textTransform: "initial",
                    boxShadow: "none",
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#fff"
                  }}
                  size="large"
                  disableRipple
                >
                  Save
                  </Button>
              </div>}
            </Form>
          )}
        </Formik>
      }
    </div>
  )
}

export default Account